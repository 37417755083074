var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.visible,
        width: _vm.width
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            size: _vm.size,
            "label-width": _vm.labelWidth
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateSubmit($event)
            }
          }
        },
        [
          _c("c-form-row", {
            attrs: { list: _vm.models, readOnly: _vm.readOnly },
            scopedSlots: _vm._u([
              {
                key: "newHospital",
                fn: function(scope) {
                  return [
                    _c(
                      "c-box",
                      [
                        !_vm.edit
                          ? _c(
                              "el-form-item",
                              _vm._b(
                                { attrs: { label: "更换新医院：" } },
                                "el-form-item",
                                scope.data.itemProps,
                                false
                              ),
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { filterable: "" },
                                    model: {
                                      value: _vm.form.newHospital,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "newHospital", $$v)
                                      },
                                      expression: "form.newHospital"
                                    }
                                  },
                                  _vm._l(_vm.hospitalNames, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.onSelectByHand(item)
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "newDepartment",
                fn: function(scope) {
                  return [
                    _c(
                      "c-box",
                      [
                        !_vm.edit
                          ? _c(
                              "el-form-item",
                              _vm._b(
                                { attrs: { label: "更换新科室：" } },
                                "el-form-item",
                                scope.data.itemProps,
                                false
                              ),
                              [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.form.newDepartment,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "newDepartment", $$v)
                                      },
                                      expression: "form.newDepartment"
                                    }
                                  },
                                  _vm._l(_vm.deptNames, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.onDeptHand(item)
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.validateSubmit }
            },
            [_vm._v("保 存")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }