/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'hospitalName',
    itemProps: {
      label: '当前医院：' },

    component: {
      name: 'text' } },


  {
    kname: 'departmentName',
    itemProps: {
      label: '当前科室：' },

    component: {
      name: 'text' } },


  /*{
                          span: 12,
                          kname: 'hospitalName',
                          itemProps: {
                            label: '更换新医院：',
                            rules: [genRules('require')]
                          },
                          component: {
                            name: 'selector',
                            props: {
                              type: 'hospitals',
                              disableStatus: 1
                            }
                          }
                        },
                        {
                          span: 12,
                          kname: 'departmentName',
                          itemProps: {
                            label: '更换新科室：',
                            rules: [genRules('require')]
                          },
                          component: {
                            name: 'selector',
                            props: {
                              type: 'departments',
                              disableStatus: 1
                            }
                          }
                        }*/
  {
    kname: 'newHospital',
    itemProps: {
      label: '更换新医院：' },

    component: {} },

  {
    kname: 'newDepartment',
    itemProps: {
      label: '更换新科室：' } }];});




// form 默认值
export var deptFormOptions = function deptFormOptions() {return {
    li: [{}, {}, {}, {}, {}, {}, {}, {}] };};